import RatioBox from "components/UI/RatioBox"
import { Checkmark } from "nzk-react-components"
import React from "react"
import styled, { css } from "styled-components"
import { useZookeeperSelectionState } from "../index.state"

const Wrapper = styled.div`
  margin-bottom: 10px;
  border-radius: 50px;
  font-family: "Rammetto One";
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > .artwork {
    position: relative;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 100%;
    padding: 5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    > .tickbox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 20%;
      width: 20%;
      border-radius: 12px;
      background-color: #8cc63f;
      visibility: hidden;
    }
  }

  ${(props: { selected: boolean }) =>
    props.selected &&
    css`
      > .artwork {
        border: 5px solid #8cc63f;
        > .tickbox {
          visibility: visible;
        }
      }
    `}
  > .animal-info {
    font-family: "Libre Baskerville";
    > .animal-info--name {
      color: #662d91;
      font-size: 18px;
      margin-bottom: 8px;
    }
    > .animal-info--date {
      font-size: 11px;
    }
  }
`

export interface IAnimal {
  _id: string
  name: string
  createdAt: number
  artwork: {
    url: string
  }
}

interface IProps extends IAnimal {
  selected: boolean
}

const Zookeeper = (props: IProps) => {
  const { selectAnimal } = useZookeeperSelectionState()

  return (
    <>
      <Wrapper selected={props.selected} onClick={() => selectAnimal(props)}>
        <div className="artwork">
          <RatioBox ratio="1:1">
            <img src={props.artwork.url} alt={props.name} />
          </RatioBox>
          <div className="tickbox">
            <Checkmark />
          </div>
        </div>

        <div className="animal-info">
          <div className="animal-info--name">{props.name || ""}</div>
        </div>
      </Wrapper>
    </>
  )
}

Zookeeper.defaultProps = {}

export default Zookeeper
