import { formatAmount } from "components/Strapi/StrapiPlanHero"
import { Button, ArrowLeft } from "nzk-react-components"
import { useDrawingTool } from "@wonkystar/drawing-tool"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
// @ts-ignore
import { useShoppingCart } from "use-shopping-cart"
import { navigate } from "gatsby"
import BookFetcher from "../../../BookFetcher"
import { Steps, useBookCreatorState } from "../../index.state"
import * as s from "../index.styles"

const Title = styled.div`
  margin-bottom: 12px;
  color: #662d91;
  font-size: 17px;
`

const Options = styled.div`
  display: flex;
  justify-content: center;
  > * {
    margin-right: 20px;
  }
  > :last-child {
    margin-right: 0;
  }
  margin-bottom: 60px;
  margin-top: 30px;
  flex-wrap: wrap;
`

const Header = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  color: #662d91;
`

const BackButton = styled.div`
  font-size: 14px;
  display: flex;
  cursor: pointer;
  align-items: center;
  > :first-child {
    margin-right: 12px;
  }
`

const Option = styled.div`
  position: relative;
  background-color: #662d91;
  border: 3px solid #9026c1;
  color: #fff;
  border-radius: 20px;
  font-family: "Libre Baskerville";
  padding: 15px 30px;
  max-width: 213px;
  padding-top: 40px;
  .option--button {
    position: absolute;
    width: 100%;
    display: inline-flex;
    top: -20px;
    left: 0;
    justify-content: center;
  }
  .option-content {
    text-align: center;
  }
  .option-content--title {
    font-family: "Rammetto One";
    font-size: 15px;
  }
  .option-content--price {
    font-size: 33px;
  }
  .option-content--small-disclaimer {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
  }
  .option-content--small-info {
    font-size: 11px;
    margin-top: 12px;
  }

  @media (max-width: 486px) {
    margin-right: 0;
    max-width: 233px;
    margin-bottom: 30px;
  }
`

const BookPreview = () => {
  const { data, product, buyOptions, setCurrentStep, editing } =
    useBookCreatorState()
  const { clearCache: clearDrawingToolCache, exportCache } = useDrawingTool()
  const { addItem, cartDetails, removeItem } = useShoppingCart()
  const [newCover, setNewCover] = useState<any>(null)

  useEffect(() => {
    if (!data.animalImg) setCurrentStep(Steps.CHOOSE_ANIMAL)
    else if (!data.animalName) setCurrentStep(Steps.ANIMAL_NAME)
    else if (!data.zookeeperName) setCurrentStep(Steps.ANIMAL_NAME)
    else if (!data.customMessage) setCurrentStep(Steps.ADD_DEDICATION)
  }, [data])

  const onAddToBasket = sku => {
    const itemId = `${sku.sku}_${data.animalName?.toLowerCase()}`
    const itemCache = exportCache()
    if (itemCache) {
      localStorage.setItem(`${itemId}-drawing-cache`, itemCache)
    }
    clearDrawingToolCache()
    addItem(
      {
        id: itemId,
        sku: sku.sku,
        price: sku.pricing.amount,
        currency: sku.pricing.currency.toUpperCase(),
        name: product.name,
        description: sku.shortDescription,
        image: sku.thumbnail?.url || product.thumbnail?.url,
        metadata: {
          countryCodes: sku.pricing.countries.map(c => c.code),
          warehouse: sku.warehouse,
          digital: product.digital,
          shippingRate: sku.pricing.shippingRate,
          shopShippingRate: sku.pricing.shopShippingRate,
          personalisedOptions: {
            username: data.zookeeperName,
            animalImg: data.animalImg,
            customMessage: data.customMessage,
            animalName: data.animalName,
            favouriteSkill: data.favouriteSkill,
          },
        },
      },
      { count: 1 }
    )
    setTimeout(() => {
      navigate("/shop/checkout")
    }, 200)
  }

  const onSaveUpdate = sku => {
    const item = { ...cartDetails[editing] }
    removeItem(item.id)
    addItem(
      {
        ...item,
        sku: sku.sku,
        price: sku.pricing.amount,
        currency: sku.pricing.currency.toUpperCase(),
        description: sku.shortDescription,
        image: sku.thumbnail?.url || product.thumbnail?.url,
        metadata: {
          ...item.metadata,
          countryCodes: sku.pricing.countries.map(c => c.code),
          warehouse: sku.warehouse,
          shippingRate: sku.pricing.shippingRate,
          shopShippingRate: sku.pricing.shopShippingRate,
          personalisedOptions: {
            username: data.zookeeperName,
            animalImg: data.animalImg,
            customMessage: data.customMessage,
            animalName: data.animalName,
            favouriteSkill: data.favouriteSkill,
          },
        },
      },
      { count: item.quantity }
    )
  }

  useEffect(() => {
    if (editing && newCover) {
      onSaveUpdate(newCover)
      setTimeout(() => {
        navigate("/shop/checkout")
      }, 100)
    }
  }, [newCover])

  return (
    <s.Step>
      <Header>
        <BackButton>
          <Button
            round
            theme="primary"
            size="x-small"
            onClick={() => setCurrentStep(Steps.ANIMAL_NAME)}
          >
            <ArrowLeft />
          </Button>
          Confirmation
        </BackButton>
      </Header>
      <s.StepContent>
        <Title>Key Page Preview</Title>
        <BookFetcher
          username={data.zookeeperName as string}
          favouriteSkill={data.favouriteSkill as string}
          animalName={data.animalName as string}
          animalImg={data.animalImg as string}
          customMessage={data.customMessage as string}
        />
        {editing && (
          <>
            <Options>
              {buyOptions.map(option => {
                const selected = newCover
                  ? option.sku === newCover.sku
                  : option.sku === cartDetails[editing].sku
                return (
                  <Option key={option.id}>
                    <div className="option--button">
                      <Button
                        theme={selected ? "confirm" : "primary"}
                        size="small"
                        onClick={() => setNewCover(option)}
                      >
                        {selected ? "Selected" : "Select"}
                      </Button>
                    </div>
                    <div className="option-content">
                      <div className="option-content--title">
                        {option.title}
                      </div>
                      <div className="option-content--price">
                        {formatAmount(
                          option.pricing.currency,
                          option.pricing.amount
                        )}
                      </div>
                      <div className="option-content--small-disclaimer">
                        Excluding delivery
                      </div>
                      <div className="option-content--small-info">
                        {option.title === "Softback"
                          ? "Our original softcover books are made with thick, child friendly paper."
                          : "Our hardcover format has a sturdy cover with a lovely matte finish and thick, child-friendly pages."}
                      </div>
                    </div>
                  </Option>
                )
              })}
            </Options>
            <div style={{ marginTop: "-20px" }}>
              <Button
                theme="confirm"
                size="small"
                onClick={() => {
                  navigate("/shop/checkout")
                }}
              >
                Save and return to checkout
              </Button>
            </div>
          </>
        )}
        {!editing && (
          <Options>
            {buyOptions.map(option => (
              <Option key={option.id}>
                <div className="option--button">
                  <Button
                    theme="primary"
                    size="small"
                    onClick={() => onAddToBasket(option)}
                  >
                    Add to basket
                  </Button>
                </div>
                <div className="option-content">
                  <div className="option-content--title">{option.title}</div>
                  <div
                    className="option-content--price"
                    style={{
                      fontSize: `${
                        Math.min(
                          7 /
                            formatAmount(
                              option.pricing.currency,
                              option.pricing.amount
                            ).length,
                          1
                        ) * 33
                      }px`,
                    }}
                  >
                    {formatAmount(
                      option.pricing.currency,
                      option.pricing.amount
                    )}
                  </div>
                  <div className="option-content--small-disclaimer">
                    Excluding delivery
                  </div>
                  <div className="option-content--small-info">
                    {option.title === "Softback"
                      ? "Our original softcover books are made with thick, child friendly paper."
                      : "Our hardcover format has a sturdy cover with a lovely matte finish and thick, child-friendly pages."}
                  </div>
                </div>
              </Option>
            ))}
          </Options>
        )}
      </s.StepContent>
    </s.Step>
  )
}

export default BookPreview
