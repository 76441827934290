import Input from "components/UI/Input"
import { IconButton, Checkmark } from "nzk-react-components"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useAuthenticationState } from "../../../../../../../../../providers/AuthenticationProvider"
import { useBookCreatorState } from "../../../../index.state"

const Wrapper = styled.div`
  background-color: #ebebebee;
  padding: 12px 80px 12px 80px;
  border-radius: 20px;
`

const Field = styled.div`
  > :first-child {
    margin-bottom: 5px;
    color: #662d91;
    font-size: 18px;
    font-family: "Rammetto One";
  }
`

const Form = styled.div`
  > * {
    margin-bottom: 15px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  margin-bottom: 15px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  a {
    color: #5f2c83;
    text-decoration: underline;
  }
  > * {
    margin-bottom: 12px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

export default () => {
  const { login } = useAuthenticationState()
  const { setOverrideStepTitle } = useBookCreatorState()
  const [error, setError] = useState<string | null>(null)

  const emailRef = useRef<HTMLInputElement | null>(null)
  const passwordRef = useRef<HTMLInputElement | null>(null)

  const check = () => {
    if (!emailRef.current?.value || !passwordRef.current?.value) return false
    return true
  }

  const onLogin = async () => {
    const isValid = check()
    if (!isValid) return
    const data = await login(
      emailRef.current!.value,
      passwordRef.current!.value
    )
    if (data && data.error) {
      setError(data?.error?.message)
    }
  }

  useEffect(() => {
    setOverrideStepTitle("Login")
    return () => setOverrideStepTitle(null)
  }, [])

  return (
    <Wrapper>
      <Form>
        {error && <div style={{ color: "red" }}>{error}</div>}
        <Field>
          <div>Parent email:</div>
          <Input
            ref={emailRef}
            type="email"
            placeholder="e.g will@nightzookeeper.com"
            borderColor="#701EA8"
          />
        </Field>
        <Field>
          <div>Password:</div>
          <Input
            ref={passwordRef}
            placeholder="e.g ********"
            type="password"
            borderColor="#701EA8"
          />
        </Field>
      </Form>
      <Actions>
        <IconButton
          theme="confirm"
          size="small"
          onClick={onLogin}
          icon={<Checkmark />}
        >
          Confirm
        </IconButton>
        <a
          href="https://www.nightzookeeper.com/edu/forgot-password"
          target="_blank"
          rel="noreferrer noopener"
        >
          Forgotten your password?
        </a>
      </Actions>
    </Wrapper>
  )
}
