import RatioBox from "components/UI/RatioBox"
import { GatsbyImage } from "gatsby-plugin-image"
import { Checkmark } from "nzk-react-components"
import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"

const Wrapper = styled.div`
  margin-bottom: 10px;
  border-radius: 50px;
  font-family: "Rammetto One";
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > .artwork {
    position: relative;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 100%;
    padding: 5px;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    > .tickbox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 20%;
      width: 20%;
      border-radius: 12px;
      background-color: #8cc63f;
      visibility: hidden;
    }
  }

  ${(props: { selected: boolean }) =>
    props.selected &&
    css`
      > .artwork {
        border: 5px solid #8cc63f;
        > .tickbox {
          visibility: visible;
        }
      }
    `}
  > .animal-info {
    font-family: "Libre Baskerville";
    margin-top: 12px;
    > .animal-info--name {
      color: #662d91;
      font-size: 22px;
      margin-bottom: 8px;
    }
    > .animal-info--date {
      font-size: 11px;
    }
  }
`

export interface IAnimal {
  _id: string
  name: string
  createdAt: number
  localFile: any
  artwork: {
    url: string
  }
}

interface IProps extends IAnimal {
  selected: boolean
  selectAnimal: (animal) => void
}

const Animal = (props: IProps) => {
  const formattedDate = useMemo(() => {
    if (!props.createdAt) return null
    try {
      return new Intl.DateTimeFormat("en-gb", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(props.createdAt)
    } catch (err) {
      return null
    }
  }, [])

  return (
    <>
      <Wrapper
        selected={props.selected}
        onClick={() => props.selectAnimal(props)}
      >
        <div className="artwork">
          <RatioBox ratio="1:1">
            <GatsbyImage
              image={getGatsbyImage(props.localFile)}
              alt={props.name}
              objectFit="contain"
            />
          </RatioBox>
          <div className="tickbox">
            <Checkmark />
          </div>
        </div>

        <div className="animal-info">
          <div className="animal-info--name">{props.name || ""}</div>
          {formattedDate && (
            <div className="animal-info--date">{formattedDate}</div>
          )}
        </div>
      </Wrapper>
    </>
  )
}

Animal.defaultProps = {}

export default Animal
