import { ArrowLeft, ArrowRight, Button, Close, Download, IconButton, Search } from "nzk-react-components"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { pdfjs } from "react-pdf"
import styled, { css } from "styled-components"
import { PREVIEWABLE } from "./constants"
import LoadingSpread from "./LoadingSpread"
import Spread from "./Spread"
import useData from "./useData"

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  :before {
    position: absolute;
    top: 10px;
    left: 10px;
    content: "";
    height: calc(100vh - 20px);
    width: calc(100vw - 30px);
    background-color: #fff;
    z-index: -2;
    border-radius: 12px;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Wrapper = styled.div`
  position: relative;
  height: var(--height);
  width: calc(2 * var(--height) * 0.68);

  ${(props: { preview?: boolean }) =>
    props.preview
      ? css`
          --height: calc(100vh - 200px);
          @media (max-width: 870px) {
            --width: calc(100vw - 40px);
            --height: calc(100vh - 200px);
            height: var(--height);
            width: var(--width);
          }
        `
      : css`
          --height: calc(100vh - 100px - 321px);
          @media (max-width: 720px) {
            --width: calc(100vw - 40px);
            --height: calc(var(--width) / 2 / 0.68);
            height: var(--height);
            width: var(--width);
          }
        `}

  > * {
    height: 100%;
    width: 100%;
  }
  box-shadow: 0 3px 4px -1px rgba(0, 0, 0, 0.27),
    0 -3px 4px -1px rgba(0, 0, 0, 0.27), -3px 0px 2px 0px rgba(0, 0, 0, 0.27);
`

const FullScreenButton = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 35px;
  width: 35px;
  z-index: 10;
`

const BackButton = styled.div``

const NextButton = styled.div``

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .page-numbers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    min-width: 120px;
    margin: 0 auto;
  }
  .page-numbers--current {
    color: #662d91;
    font-size: 18px;
  }
  .page-numbers--total {
    font-size: 11px;
  }
`

const Spreads = styled.div`
  position: absolute;
  height: var(--height);
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--height);
  }
  .spread {
    > :first-child {
      height: var(--height);
    }
    z-index: 0;
  }

  .spread.current {
    z-index: 1;
    visibility: visible;
  }
  .spread.previous {
    z-index: -1;
    visibility: hidden;
  }
  .spread.next {
    z-index: 0;
    visibility: ${(props: { direction: string; swiping: boolean }) =>
      props.swiping ? "visible" : "hidden"};
  }
  ${(props: { direction: string; swiping: boolean }) =>
    props.direction === "previous" &&
    css`
      .spread.previous {
        z-index: 0;
        visibility: ${props.swiping ? "visible" : "hidden"}};
      }
      .spread.next {
        z-index: -1;
        visibility: hidden;
      }
  `}
`

interface IBookFetcherProps {
  username: string
  animalName: string
  animalImg: string
  customMessage: string
  favouriteSkill: string
}

export default (props: IBookFetcherProps) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const PREVIEW_PAGES = PREVIEWABLE[props.favouriteSkill]
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [lastLoadedIndex, setLastLoadedIndex] = useState(1)
  const [fullScreen, setFullScreen] = useState(false)
  const [cursors, setCursors] = useState(["prev", "current", "next"])
  const [loading, setLoading] = useState(false)
  const [loadingNext, setLoadingNext] = useState(false)
  const [loadingPrev, setLoadingPrev] = useState(false)
  const [direction, setDirection] = useState("next")
  const [swiping, setSwiping] = useState(false)

  const { fetchSpread, downloadSpread: downloadCurrentSpread } = useData({
    username: props.username,
    favouriteSkill: props.favouriteSkill,
    animalName: props.animalName,
    animalImg: props.animalImg,
    customMessage: props.customMessage,
  })

  const [spread1, setSpread1] = useState<any>({
    index: 0,
    spread: null,
    data: null,
    loading: false,
    classNames: "previous",
    props: { gestures: false },
  })
  const [spread2, setSpread2] = useState<any>({
    index: 1,
    spread: 1,
    data: null,
    loading: false,
    classNames: "current",
    props: { gestures: true },
  })
  const [spread3, setSpread3] = useState<any>({
    index: 2,
    spread: 2,
    data: null,
    loading: false,
    classNames: "next",
    props: { gestures: false },
  })

  const onNextSpread = useCallback(async () => {
    const nextIndex = lastLoadedIndex + 1
    const setSpreads = [setSpread1, setSpread2, setSpread3]
    const next = cursors.indexOf("next")
    const prev = cursors.indexOf("prev")
    const current = cursors.indexOf("current")

    setSpreads[next](s => ({
      ...s,
      classNames: "current",
      props: { gestures: true },
    }))
    setSpreads[current](s => ({
      ...s,
      classNames: "previous",
      props: { gestures: false },
    }))

    setSpreads[prev](s => ({
      ...s,
      spread: PREVIEW_PAGES[nextIndex],
      classNames: "next",
      props: { gestures: false },
      data: null,
    }))

    if (PREVIEW_PAGES[nextIndex]) {
      setLoadingNext(true)
      const nextData = await fetchSpread(PREVIEW_PAGES[nextIndex])
      setSpreads[prev](s => ({
        ...s,
        classNames: "next",
        data: nextData,
      }))
      setLoadingNext(false)
    }

    const newCursors = ["", "", ""]
    newCursors[next] = "current"
    newCursors[prev] = "next"
    newCursors[current] = "prev"
    setCursors(newCursors)
    return true
  }, [lastLoadedIndex])

  const onPreviousSpread = useCallback(async () => {
    const prevIndex = currentPageIndex - 2
    const setSpreads = [setSpread1, setSpread2, setSpread3]
    const next = cursors.indexOf("next")
    const prev = cursors.indexOf("prev")
    const current = cursors.indexOf("current")

    setSpreads[prev](s => ({
      ...s,
      classNames: "current",
      props: { gestures: true },
    }))
    setSpreads[current](s => ({
      ...s,
      classNames: "next",
      props: { gestures: false },
    }))

    setSpreads[next](s => ({
      ...s,
      classNames: "prev",
      data: null,
      props: { gestures: false },
    }))

    if (prevIndex >= 0) {
      setLoadingPrev(true)
      const prevData = await fetchSpread(PREVIEW_PAGES[prevIndex])
      setSpreads[next](s => ({
        ...s,
        classNames: "prev",
        spread: PREVIEW_PAGES[prevIndex],
        props: { gestures: false },
        data: prevData,
      }))
      setLoadingPrev(false)
    }

    const newCursors = ["", "", ""]
    newCursors[prev] = "current"
    newCursors[next] = "prev"
    newCursors[current] = "next"
    setCursors(newCursors)
  }, [currentPageIndex])

  const canNext = useMemo(() => {
    return !loadingNext && currentPageIndex < PREVIEW_PAGES.length - 1
  }, [loadingNext, currentPageIndex])

  const canPrevious = useMemo(() => {
    return !loadingPrev && currentPageIndex > 0
  }, [loadingPrev, currentPageIndex])

  const onPrevious = async () => {
    if (!canPrevious) return
    await onPreviousSpread()
    setCurrentPageIndex(page => page - 1)
    setLastLoadedIndex(page => page - 1)
  }

  const onNext = async () => {
    if (!canNext) return
    await onNextSpread()
    setCurrentPageIndex(page => page + 1)
    setLastLoadedIndex(page => page + 1)
  }

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const firstSpread = await fetchSpread(PREVIEW_PAGES[currentPageIndex])
      setSpread2(s => ({
        ...s,
        spread: PREVIEW_PAGES[currentPageIndex],
        data: firstSpread,
      }))
      const nextSpread = await fetchSpread(PREVIEW_PAGES[currentPageIndex + 1])
      setSpread3(s => ({
        ...s,
        spread: PREVIEW_PAGES[currentPageIndex + 1],
        data: nextSpread,
      }))
      setCurrentPageIndex(currentPageIndex)
      setLastLoadedIndex(currentPageIndex + 1)
      setLoading(false)
    }
    fetch()
  }, [])

  const updateDirection = d => {
    if (d === direction) return
    setDirection(d)
  }

  const updateSwiping = d => {
    if (d === swiping) return
    setSwiping(d)
  }

  const spread1Component = useMemo(() => {
    if (!spread1 || !spread1.data || !(spread1.data.byteLength > 0)) return null
    return (
      <Spread
        data={spread1.data}
        {...spread1.props}
        cover={spread1.spread === "cover"}
        canNext={canNext}
        setDirection={updateDirection}
        updateSwiping={updateSwiping}
        spread={spread1.spread.toString()}
        canPrevious={canPrevious}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    )
  }, [spread1, canNext, canPrevious, onNext, onPrevious])

  const spread2Component = useMemo(() => {
    if (!spread2 || !spread2.data || !(spread2.data.byteLength > 0)) return null
    return (
      <Spread
        data={spread2.data}
        {...spread2.props}
        cover={spread2.spread === "cover"}
        canNext={canNext}
        setDirection={updateDirection}
        updateSwiping={updateSwiping}
        spread={spread2.spread.toString()}
        canPrevious={canPrevious}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    )
  }, [spread2, canNext, canPrevious, onNext, onPrevious])

  const spread3Component = useMemo(() => {
    if (!spread3 || !spread3.data || !(spread3.data.byteLength > 0)) return null
    return (
      <Spread
        data={spread3.data}
        {...spread3.props}
        setDirection={updateDirection}
        updateSwiping={updateSwiping}
        cover={spread3.spread === "cover"}
        canNext={canNext}
        spread={spread3.spread.toString()}
        canPrevious={canPrevious}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    )
  }, [spread3, canNext, canPrevious, onNext, onPrevious])

  const PDF = (
    <Spreads direction={direction} swiping={swiping}>
      <div className={`spread ${spread1.classNames}`} id="buffer-1">
        {spread1Component}
      </div>
      <div className={`spread ${spread2.classNames}`} id="buffer-2">
        {spread2Component}
      </div>
      <div className={`spread ${spread3.classNames}`} id="buffer-3">
        {spread3Component}
      </div>
    </Spreads>
  )

  if (loading) {
    return (
      <Wrapper style={{ marginBottom: "30px" }}>
        <LoadingSpread />
      </Wrapper>
    )
  }

  if (fullScreen) {
    return (
      <Overlay>
        <CloseButton onClick={() => setFullScreen(false)}>
          <Button round theme="red" size="regular">
            <Close />
          </Button>
        </CloseButton>
        <Wrapper preview>{PDF}</Wrapper>
        <Pagination>
          <BackButton>
            <Button
              round
              theme="primary"
              size="small"
              onClick={onPrevious}
              disabled={!canPrevious}
            >
              <ArrowLeft />
            </Button>
          </BackButton>
          <div className="page-numbers">
            <div className="page-numbers--current">
              {PREVIEW_PAGES[currentPageIndex] === "cover"
                ? "Cover"
                : PREVIEW_PAGES[currentPageIndex].join("-")}
            </div>
            <div className="page-numbers--total">(of 228)</div>
          </div>
          <NextButton>
            <Button
              round
              theme="primary"
              size="small"
              onClick={onNext}
              disabled={!canNext}
            >
              <ArrowRight />
            </Button>
          </NextButton>
        </Pagination>
      </Overlay>
    )
  }

  return (
    <>
      <Wrapper>
        {PDF}
        <FullScreenButton onClick={() => setFullScreen(true)}>
          <Button size="small" round theme="orange">
            <Search />
          </Button>
        </FullScreenButton>
      </Wrapper>
      <Pagination>
        <BackButton>
          <Button
            round
            theme="primary"
            size="small"
            onClick={onPrevious}
            disabled={!canPrevious}
          >
            <ArrowLeft />
          </Button>
        </BackButton>
        <div className="page-numbers">
          <div className="page-numbers--current">
            {PREVIEW_PAGES[currentPageIndex] === "cover"
              ? "Cover"
              : PREVIEW_PAGES[currentPageIndex].join("-")}
          </div>
          <div className="page-numbers--total">(of 228)</div>
        </div>
        <NextButton>
          <Button
            round
            theme="primary"
            size="small"
            onClick={onNext}
            disabled={!canNext}
          >
            <ArrowRight />
          </Button>
        </NextButton>
      </Pagination>
      {false && PREVIEW_PAGES[currentPageIndex] === "cover" && (
        <div
          style={{
            marginTop: "-5px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#662d91",
          }}
        >
          <p>Share this cover on socials!</p>
          {/* eslint-disable-next-line */}
          <a id="download-spread" href="#">
            <IconButton
              size="x-small"
              theme="primary"
              onClick={() => downloadCurrentSpread("cover")}
              icon={<Download />}
            >
              Download Cover
            </IconButton>
          </a>
        </div>
      )}
    </>
  )
}
