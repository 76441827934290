import { IconButton, Button, Checkmark } from "nzk-react-components"
import React from "react"
import styled, { css } from "styled-components"

interface IFlowStepsProps {
  steps: {
    id: string
    title: string
    checked?: boolean
    subTitle?: string
    onClick?: () => void
  }[]
  onStepChange: (id: string) => void
  selectedStep: string
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: #ebebeb;
  border-radius: 100px;
  margin: 0 auto;
  padding: 10px 30px;

  > :last-child {
    margin-right: 0px;
    > :after {
      display: none;
    }
  }
`

const DesktopButton = styled.div`
  > :first-child {
    z-index: 1;
  }
  @media (max-width: 920px) {
    visibility: hidden;
    display: none;
  }
`

const MobileButton = styled.div`
  > :first-child {
    z-index: 1;
  }
  @media (min-width: 920px) {
    visibility: hidden;
    display: none;
  }
`

interface IStepProps {
  checked: boolean
  selected: boolean
}

const Step = styled.div`
  position: relative;
  margin-right: 40px;
  @media (max-width: 920px) {
    margin-right: 15px;
  }
  > :after {
    content: "";
    position: absolute;
    left: 50%;
    top: calc(50% - 8px);
    width: 100%;
    height: 15px;
    background: #878787;
  }
  ${(props: IStepProps) => {
    if (props.selected) {
      return css`
        > :after {
          background-color: #6f1ea9;
        }
      `
    }
    if (props.checked) {
      return css`
        > :after {
          background-color: #8cc63f;
        }
      `
    }
  }}
`

const FlowSteps = (props: IFlowStepsProps) => {
  return (
    <Wrapper className="flowsteps">
      {props.steps.map((step, i) => {
        const selected = props.selectedStep === step.id
        let buttonProps: any = { backgroundColor: "#878787" }
        if (step.checked) {
          buttonProps = { theme: "confirm" }
        }
        if (selected) {
          buttonProps = { theme: "purple" }
        }
        if (step.onClick) {
          buttonProps = { ...buttonProps, onClick: step.onClick }
        }

        return (
          <Step
            key={step.id}
            selected={selected}
            checked={Boolean(step.checked)}
            onClick={() => props.onStepChange(step.id)}
          >
            <MobileButton>
              <Button round {...buttonProps} size="small">
                {i + 1}
              </Button>
            </MobileButton>
            <DesktopButton>
              <IconButton
                {...buttonProps}
                size="x-small"
                icon={
                  step.checked ? (
                    <Checkmark height="35px" width="35px" />
                  ) : (
                    <div>{i + 1}</div>
                  )
                }
              >
                {step.title}
              </IconButton>
            </DesktopButton>
          </Step>
        )
      })}
    </Wrapper>
  )
}

export default FlowSteps
